/*
 * Bootstrap & theme global overrides
 */

a.card {
  color: inherit;
}

a.card:hover {
  text-decoration: inherit;
}
