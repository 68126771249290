/*
 * Bootstrap & theme global overrides
 */

.btn {
  padding: .375rem .75rem;
}

.btn-group-sm > .btn, .btn-sm {
  padding: .25rem .5rem;
}

.btn-xs {
  padding: 0.15rem 0.4rem;
  font-size: 0.7rem;
  line-height: 1.4;
  border-radius: 0.2rem;
}
