// variables.scss
// Use this to overwrite Bootstrap and Front variables
//


// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
// $primary: $red;
// $font-size-base: .875rem;

// Front's primary color has lighter as well as darker color versions,
// and they also need to be changed upon changing the primary color to a different hex code - otherwise the color of some blocks might look not professional.
// Here is a list of colors that associate these changes:
// $primary-darker:                                              $red;
// $primary-lighter:                                             $red-lighter;
// $info:                                                        $red-lighter;


// The color of the SVG loader should be modified manually.
// See assets/svg/preloaders/circle-preloader.svg
$gospeak-lighter: #c6a5ff;
$gospeak: #7655C7;
$gospeak-darker: #260577;

$primary: $gospeak;
$primary-darker: $gospeak-darker;
$primary-lighter: $gospeak-lighter;

$local-color: #7fd1b9;
$dev-color: #de6e4b;
$staging-color: #a81817;
