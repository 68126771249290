/*------------------------------------
  Background Gradients
------------------------------------*/

.gradient-half-primary-body-v1 {
	@include gradient-x($hs-start-color: $gradient-half-indigo, $hs-end-color: $gradient-half-primary-lighter, $deg: 150deg);
  background-attachment: fixed;
}

.gradient-half-primary-v1 {
	@include gradient-x($hs-start-color: $gradient-half-indigo, $hs-end-color: $gradient-half-primary-lighter, $deg: 150deg);
}

.gradient-half-primary-v2 {
	@include gradient-y($hs-start-color: rgba($gradient-half-primary, .05), $hs-end-color: transparent);
}

.gradient-half-primary-v3 {
	@include gradient-y($hs-start-color: rgba($gradient-half-primary, .1), $hs-end-color: transparent);
}

.gradient-half-primary-v4 {
	@include gradient-x($hs-start-color: $gradient-half-indigo, $hs-end-color: $gradient-half-primary-lighter, $hs-end-percent: 85%, $deg: 150deg);
}

.gradient-half-primary-v5 {
	@include gradient-x($hs-start-color: $gradient-half-primary, $hs-end-color: $gradient-half-indigo, $deg: 150deg);
}

.gradient-half-info-v1 {
	@include gradient-x($hs-start-color: $gradient-half-primary, $hs-end-color: $gradient-half-info, $deg: 0deg);
}

.gradient-half-warning-v1 {
	@include gradient-x($hs-start-color: $gradient-half-warning, $hs-end-color: $gradient-half-danger, $hs-start-percent: 30%, $deg: 25deg);
}

.gradient-half-warning-v2 {
	@include gradient-x($hs-start-color: $gradient-half-warning, $hs-end-color: $gradient-half-warning-darker, $deg: 150deg);
}

.gradient-half-warning-v3 {
	@include gradient-x($hs-start-color: $gradient-half-warning, $hs-end-color: $gradient-half-danger, $deg: 150deg);
}