/*
 * Bootstrap & theme global overrides
 */

.form-control {
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
}

.form-control::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.is-invalid .invalid-feedback,
.form-row.is-invalid ~ .invalid-feedback {
  display: block;
}

.input-group > .input-group-prepend ~ .hideShowPassword-wrapper > .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--bootstrap .select2-selection--single {
  height: 38px;
  padding: 8px 24px 8px 12px;
}

.tt-header {
  padding: 3px 10px;
}

span.twitter-typeahead .tt-suggestion.tt-cursor,
span.twitter-typeahead .tt-suggestion.tt-cursor:hover {
  color: inherit;
  background-color: #f5f5f5;
}
